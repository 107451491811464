import "./App.css";
import styled from "styled-components";

//@ts-ignore
import RRuleGenerator from "react-rrule-generator";
//@ts-ignore
import DateTimePicker from "react-datetime-picker";
import { useState } from "react";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fde39a;
`;

const Container = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  padding: 50px 20px;
`;

const Element = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  width: 400px;
`;

const Checkbox = styled.input`
  transform: scale(1.5);
`;

const TextBox = styled.textarea`
  width: 262.17px;
  height: 80px;
`;

const Button = styled.button`
  width: 10rem;
`;

function App() {
  const [recurring, setRecurring] = useState(false);
  const [recurrence, setRrule] = useState(
    "RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1"
  );
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [description, setDescription] = useState<string>();
  const [summary, setSummary] = useState<string>();
  const [location, setLocation] = useState<string>();

  const [googleURL, setGoogleURL] = useState<string>();

  async function handleSumbmit() {
    const googleResponse = await fetch(
      `https://calendar-api.bit.learnistic.com/example/google/auth`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          summary,
          location,
          description,
          recurrence: recurring ? [recurrence] : [],
          start: startDateTime,
          end: endDateTime,
        }),
      }
    );

    const data = await googleResponse.json();

    console.log(data.url);
    setGoogleURL(data.url);
  }

  return (
    <Wrapper>
      <Container>
        <Element>
          Summary:{" "}
          <TextBox
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
        </Element>
        <Element>
          Description:{" "}
          <TextBox
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Element>
        <Element>
          Location:{" "}
          <TextBox
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Element>

        <div>
          <Element>
            Start:
            <DateTimePicker onChange={setStartDateTime} value={startDateTime} />
          </Element>
          <Element>
            End:
            <DateTimePicker onChange={setEndDateTime} value={endDateTime} />
          </Element>
        </div>
        <Element>
          Recurring:
          <div style={{ width: "192px" }}>
            <Checkbox
              type="checkbox"
              onChange={(event) => {
                setRecurring(event.target.checked);
              }}
              checked={recurring}
            />
          </div>
        </Element>
        {recurring && (
          <div>
            <RRuleGenerator
              onChange={(rrule: string) => setRrule(rrule)}
              value={recurrence}
            />
            <br />
            {recurrence}
          </div>
        )}
        <br />
        <Button onClick={handleSumbmit}>Generate Link</Button>
        {googleURL && (
          <a href={googleURL} target="_blank">
            Google URL
          </a>
        )}
      </Container>
    </Wrapper>
  );
}

export default App;
